// sizingComponentCalculations.js
import dayjs from 'dayjs';

import { round, mapValues } from 'lodash';
import { groupBy, partition, sumBy, meanBy } from 'lodash';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(timezone);


// import { format, toZonedTime } from 'date-fns-tz';
/**
 * @typedef {Object} Clamp
 * @property {string} id
 * @property {boolean} enabled
 * @property {boolean} minus
 */

/**
 * @typedef {Object} Panel
 * @property {string} name
 * @property {string} make - The manufacturer of the panel
 * @property {number} WP - Wattage of the panel
 * @property {number} VOC - Open circuit voltage of the panel
 * @property {number} VMP - Voltage at maximum power of the panel
 * @property {number} ISC - Short circuit current of the panel
 * @property {number} temp_coefficient_VC - Temperature coefficient of the panel
 * @property {number} price - Price of the panel
 * @property {number} loss_factor - Loss factor of the panel
 * @property {string} component_uuid - UUID of the panel component
 * @property {number} weight - Weight of the panel
 * @property {number} height - Height of the panel
 * @property {number} width - Width of the panel
 */

/**
 * @typedef {Object} MPPT
 * @property {string} name
 * @property {string} make
 * @property {number} WP
 * @property {number} VOC
 * @property {number} VMP
 * @property {number} ISC
 * @property {number} temp_coefficient_VC
 * @property {number} price
 * @property {number} kW
 * @property {number} loss_factor
 * @property {string} component_uuid
 */

/**
 * @typedef {Object} Inverter
 * @property {number} rated_output
 * @property {number} price
 * @property {number} parallel_limit
 * @property {number} max_input_voltage
 * @property {number} num_mppts
 * @property {number} num_inputs_per_mppts
 * @property {number} max_charge_per_mppts_isc
 * @property {string} design_voltage
 * @property {string} component_uuid
 * @property {MPPT[]} mppts
 */

/**
 * @typedef {Object} HourReading
 * @property {string} time
 * @property {number} mean_clamp1i
 * @property {number} mean_clamp1v
 * @property {number} mean_clamp2i
 * @property {number} mean_clamp2v
 * @property {number} mean_clamp3i
 * @property {number} mean_clamp3v
 */

/**
 * @typedef {Object} SizingReport
 * @property {number} dailyUseKwh
 * @property {number} peakCapacityCovered
 * @property {number} pvWattsAcAnnual
 * @property {HourReading[]} hourReadings

 * @property {Clamp[]} clamps
 * @property {Panel} panel
 * @property {number} noPanels
 * @property {{ inverters: Inverter[], panels: Panel[], panelCount: number }} components
 */



const pv_generation_mapping = {
  "00:00:00": 0,
  "00:15:00": 0,
  "00:30:00": 0,
  "00:45:00": 0,
  "01:00:00": 0,
  "01:15:00": 0,
  "01:30:00": 0,
  "01:45:00": 0,
  "02:00:00": 0,
  "02:15:00": 0,
  "02:30:00": 0,
  "02:45:00": 0,
  "03:00:00": 0,
  "03:15:00": 0,
  "03:30:00": 0,
  "03:45:00": 0,
  "04:00:00": 0,
  "04:15:00": 0,
  "04:30:00": 0,
  "04:45:00": 0,
  "05:00:00": 0,
  "05:15:00": 0,
  "05:30:00": 0,
  "05:45:00": 0,
  "06:00:00": 0,
  "06:15:00": 0.00364836693333333,
  "06:30:00": 0.0108408616666667,
  "06:45:00": 0.0180333564666667,
  "07:00:00": 0.0252258513333333,
  "07:15:00": 0.0541843873333333,
  "07:30:00": 0.122904795,
  "07:45:00": 0.1924322446,
  "08:00:00": 0.2619596942,
  "08:15:00": 0.3256520732,
  "08:30:00": 0.370284920133333,
  "08:45:00": 0.413439888733333,
  "09:00:00": 0.456594857533333,
  "09:15:00": 0.5017929118,
  "09:30:00": 0.559589993,
  "09:45:00": 0.6195274496,
  "10:00:00": 0.679464906133333,
  "10:15:00": 0.738151494066667,
  "10:30:00": 0.7796038916,
  "10:45:00": 0.815566365533333,
  "11:00:00": 0.851528839466667,
  "11:15:00": 0.887354644466667,
  "11:30:00": 0.9156358582,
  "11:45:00": 0.939610840933333,
  "12:00:00": 0.963585823466667,
  "12:15:00": 0.987560806066667,
  "12:30:00": 0.998846421133333,
  "12:45:00": 0.996448922866667,
  "13:00:00": 0.9940514246,
  "13:15:00": 0.991653926333333,
  "13:30:00": 0.979029881866667,
  "13:45:00": 0.946025017333333,
  "14:00:00": 0.9124600418,
  "14:15:00": 0.878895066,
  "14:30:00": 0.842397498266667,
  "14:45:00": 0.795378735266667,
  "15:00:00": 0.74742877,
  "15:15:00": 0.699478804666667,
  "15:30:00": 0.6475886032,
  "15:45:00": 0.568763029866667,
  "16:00:00": 0.484850590666667,
  "16:15:00": 0.400938151466667,
  "16:30:00": 0.3176539264,
  "16:45:00": 0.244579569133333,
  "17:00:00": 0.175052119533333,
  "17:15:00": 0.105524669933333,
  "17:30:00": 0.0365290711333333,
  "17:45:00": 0.0084989576,
  "18:00:00": 0.00610145933333333,
  "18:15:00": 0.00370396113333333,
  "18:30:00": 0.0013064628,
  "18:45:00": 0.00000185314,
  "19:00:00": 0,
  "19:15:00": 0,
  "19:30:00": 0,
  "19:45:00": 0,
  "20:00:00": 0,
  "20:15:00": 0,
  "20:30:00": 0,
  "20:45:00": 0,
  "21:00:00": 0,
  "21:15:00": 0,
  "21:30:00": 0,
  "21:45:00": 0,
  "22:00:00": 0,
  "22:15:00": 0,
  "22:30:00": 0,
  "22:45:00": 0,
  "23:00:00": 0,
  "23:15:00": 0,
  "23:30:00": 0,
  "23:45:00": 0
}

/**
 * Function to get PV generation based on hour string and pv_mapping.
 * 
 * @param {string} hourStr - The hour string in "HH:MM:SS" format.
 * @param {Object} pvMapping - The mapping of time to PV generation values.
 * 
 * @returns {number} The PV generation value for the given hour string.
 */
function getPvGeneration(hourStr, pvMapping) {
  return pvMapping[hourStr] || 0;
}

import logger from '../utils/logger';
logger.enableLogging('sizingComponentCalculations');

/**
 * Converts raw hourly readings to the desired format by combining wattages of all clamps.
 * 
 * @param {*} rawDataArray 
 * @returns 
 */
export const formatHourlyRawDataCombined_SingleClamp = (rawDataArray) => {
  // console.log("rawDataArray", rawDataArray);
  const combined_data = [];

  rawDataArray.forEach(rawData => {
    const time = rawData.time; // Use the time field from the JSON data

    // Calculate the total wattage using mean_i and mean_v

    const totalWatt = rawData.mean_watt; //total_power for old hourReadings

    combined_data.push({ time, max: totalWatt });
  });

  // console.log("combined_data", combined_data);
  return combined_data;
};

/**
 * Converts raw hourly readings to the desired format by combining wattages of all clamps.
 * 
 * @param {*} rawDataArray 
 * @returns 
 */
export const formatHourlyRawDataCombined = (rawDataArray) => {

  const combined_data = [];

  rawDataArray.forEach(rawData => {
    const time = rawData.clamp1v.time; // Assuming all times are the same for a given hour



    const clamp1watt_mean = rawData.clamp1v.value * rawData.clamp1i.value;
    const clamp2watt_mean = rawData.clamp2v.value * rawData.clamp2i.value;
    const clamp3watt_mean = rawData.clamp3v.value * rawData.clamp3i.value;

    const clamp1watt_max = rawData.clamp1v.max * rawData.clamp1i.max
    const clamp2watt_max = rawData.clamp2v.max * rawData.clamp2i.max
    const clamp3watt_max = rawData.clamp3v.max * rawData.clamp3i.max


    const totalWatt = clamp1watt_mean + clamp2watt_mean + clamp3watt_mean;
    const maximum = clamp1watt_max + clamp2watt_max + clamp3watt_max;

    combined_data.push({ time, max: totalWatt, maximum: maximum });
  });

  return combined_data;
};

/** 
 * Calculates the inverter operating capacity graph data.
 * 
 * @param {Array} hourReadings - The hourly readings data.
 * @param {number} numInverters - The number of inverters.
 * @param {number} maxOutputPerInverter - The maximum output per inverter (in kW).
 * @returns {Array} - The formatted data for the inverter operating capacity chart.
 */
const calculateInverterOperatingCapacityGraphData = (hourReadings, numInverters, maxOutputPerInverter, isThreePhase) => {
  // Calculate the total max output for all inverters. Updated to use three phase now considering three phase
  const totalMaxOutput = (numInverters / (isThreePhase ? 3 : 1)) * maxOutputPerInverter;

  // const operatingCapacityProjections = formatHourlyRawDataCombined(hourReadings);

  // console.log("isThreePhase", isThreePhase);
  // console.log("numInverters", numInverters);
  console.log("maxOutputPerInverter", maxOutputPerInverter);
  console.log("totalMaxOutput", totalMaxOutput);

  // console.log("hourReadings", hourReadings); 
  // console.log("totalMaxOutput", totalMaxOutput);


  // Select a random 10% of the hourReadings
  // const sampleSize = Math.ceil(hourReadings.length * 0.1);
  // const sampledReadings = hourReadings.sort(() => 0.5 - Math.random()).slice(0, sampleSize);

  // we remove percentage in favor of the actual values for accuracy
  const sampledReadings = hourReadings;
  // Convert sample peaks to kilowatts and calculate operating capacity projections
  const operatingCapacityProjections = sampledReadings.map(reading => {
    // console.log("reading", reading);
    const totalPowerKw = reading.mean_watt / 1000; // Convert to kW total_power
    const operatingCapacity = (totalPowerKw / totalMaxOutput) * 100; // Percentage of max capacity
    return operatingCapacity;
  });

  // console.log("mean", sampledReadings[0].mean_watt);

  // Find the maximum operating capacity
  // const maxOperatingCapacity = totalMaxOutput;

  // console.log("maxOperatingCapacity", maxOperatingCapacity);

  // Calculate each value as a percentage of the maximum operating capacity
  const percentageValues = operatingCapacityProjections.map(value => (value));// / maxOperatingCapacity) * 100);
  // const percentageValues = operatingCapacityProjections.map(projection => ((projection.maximum/1000) / peakKW) * 100);

  // console.log("percentageValues", percentageValues);

  // Define the ranges
  const ranges = [
    { low: 0, high: 25 },
    { low: 25, high: 50 },
    { low: 50, high: 75 },
    { low: 75, high: 10000 }
  ];
  const rangeCounts = Array(ranges.length).fill(0);

  // Count the number of projections in each range
  percentageValues.forEach(projection => {
    ranges.forEach((range, index) => {
      if (range.low <= projection && projection <= range.high) {
        rangeCounts[index] += 1;
      }
    });
  });



  // console.log("numInverters", numInverters);
  // console.log("maxOutputPerInverter", maxOutputPerInverter);


  // Calculate the percentage of time in each range
  const totalProjections = percentageValues.length;
  const percentageTimeInRanges = rangeCounts.map(count => (count / totalProjections) * 100);

  // console.log("percentageTimeInRanges", percentageTimeInRanges)

  const xLabels = ["0-25%", "25-50%", "50-75%", "75-100%"];
  const yValues = percentageTimeInRanges;

  // console.log("percentageTimeInRanges", percentageTimeInRanges);

  const chartData = [{
    x: xLabels,
    y: yValues,
    type: 'bar',
    marker: { color: ['#1F77B4', '#1F77B4', '#1F77B4', '#FF7F0E'] },
    hoverinfo: 'x+y',
    name: 'Percentage Time in Range',
    text: yValues.map(value => `${value.toFixed(2)}%`),
    textposition: 'auto',
    insidetextanchor: 'middle'
  }];

  return chartData;
};

export const getAVGkw = (hourReadings) => {
  // Calculate the average power consumption per hour
  const avgKw = hourReadings.reduce((sum, reading) => sum + reading.mean_watt, 0) / hourReadings.length;
  return avgKw / 1000; // Convert to kW
}




export const dayNightAverage = (wattsPerHour) => {
  // Helper function to extract the date in YYYY-MM-DD format
  const getDateStr = (dateInput) => {
    // Convert the Date object to a string in YYYY-MM-DD format
    const dateObj = new Date(dateInput);

    if (!(dateObj instanceof Date)) { // Check if dateObj is an instance of Date
      return 'Invalid Date'; // or handle it as appropriate for your use case
    }
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // Helper function to get the day of the week (0-6)
  const getDayOfWeek = (date) => new Date(date).getDay();

  // Step 1: Group watts per hour data by date and day (YYYY-MM-DD)
  const groupedData = groupBy(wattsPerHour, (item) => {
    return `${getDateStr(item.time)}-${getDayOfWeek(item.time)}`;
  });

  // Step 2: Partition each group into days and nights (8 AM to 4 PM)
  const partitionedData = mapValues(groupedData, (group) => {
    return partition(group, (item) => {
      const hours = new Date(item.time).getUTCHours();
      return hours >= 8 && hours <= 16;
    });
  });

  // Step 3: Calculate the total watts for days and nights separately
  const dayNightTotals = Object.values(partitionedData).map((piece) => {
    const dayTotal = sumBy(piece[0], 'mean_watt');
    const nightTotal = sumBy(piece[1], 'mean_watt');
    return { dayTotal, nightTotal };
  });

  // Step 4: Calculate the average watts for days and nights
  const dayAverage = roundTo(meanBy(dayNightTotals, 'dayTotal'), 0);
  const nightAverage = roundTo(meanBy(dayNightTotals, 'nightTotal'), 0);

  // Step 5: Return the results
  return { dayAverage, nightAverage };
};


/**
 * Calculate daily sizing view estimate
 * 
 * @param {Object} sizingReport - The sizing report containing various parameters.
 * @param {number} sizingReport.dailyUseKwh - The daily energy usage in kWh.
 * @param {number} sizingReport.solar_array_sizing - The peak capacity covered.
 * @param {number} sizingReport.pvWattsAcAnnual - The annual AC output of the PV system in watts.
 * @param {number} sizingReport.batteryCapacityKwh - The battery capacity in kWh.
 * @param {number} sizingReport.inverterCapacityKw - The inverter capacity in kW.
 * @param {Array<Object>} sizingReport.hourReadings - Array of hour readings.
 * @param {Array<Object>} sizingReport.clamps - Array of clamps.
 * @param {Object} sizingReport.panel - The panel object containing panel details.
 * @param {number} sizingReport.pvCount - The number of panels.
 * 
 * @returns {Object} The daily sizing view estimate results.
 * @returns {number} returns.dailyEstimate - The daily estimate in kWh.
 * @returns {number} returns.meanDayWatts - The mean watts per hour during the day.
 * @returns {number} returns.meanNightWatts - The mean watts per hour during the night.
 * @returns {number} returns.roofSize - The calculated roof size.
 * @returns {Object} returns.solarOversize - The solar oversize graph data.
 */
export const calculateDailySizingViewEstimate = (sizingReport) => {
  const {
    pvWattsAcAnnual,
    hourReadings,
    // clamps,
    panel,
    pvCount,
    system_results,
    //batteryCount,
    // overall
  } = sizingReport;

  // Calculate mean watts per hour for day and night
  // const meanDayWatts = meanWattsPerHourFromReadingsForDay(hourReadings, clamps);
  // const meanNightWatts = meanWattsPerHourFromReadingsForNight(hourReadings, clamps);

  // console.log("READINMGS", hourReadings);

  const newSplitter = dayNightAverage(hourReadings);
  // console.log("newSplitter", newSplitter);

  const meanDayWatts = newSplitter.dayAverage;
  const meanNightWatts = newSplitter.nightAverage;



  // const final_general_avg_kw = overall.avg_kwh; 
  // const final_general_avg_kw = overall.general_avg_kw;
  const final_general_avg_kw = getAVGkw(hourReadings);



  // console.log("mean day wattts", meanDayWatts);
  // console.log("mean night wattts", meanNightWatts);
  // console.log("general_avg_kw", final_general_avg_kw);




  // let totalSum = 0;
  // let total_items = 0;

  // for (let time in pv_generation_mapping) {
  //   totalSum += pv_generation_mapping[time];
  //   total_items += 1;
  // }

  // console.log("Total Sum:", totalSum, " items ", total_items);

  // const percentage_rate = 1 - (totalSum/total_items); // assuming percentage rate based in the pv_generation_mapping


  // console.log("percentage_rate", percentage_rate);




  // Calculate max watts per five minutes
  // const maxFiveMinWatts = maxWattsPerFiveMin(fiveMinMaxReadings, clamps);

  // Ensure panel and noPanels are defined before calculating roof size
  let roofSize = 0;
  if (panel && panel.width && panel.height && pvCount) {
    roofSize = roofSizeCalc(panel, pvCount);
  } else {
    console.error("Invalid panel or pvCount data:", panel, pvCount);
  }

  const solar_array_sizing = system_results.solar_panel_result.pv_array_size; // the percentage of the solar array sizing
  // console.log("solar_array_sizing", solar_array_sizing/100);
  // we then get percent and perform transform on daily amount

  // const required_daytime_kwh = ((meanDayWatts / 1000) * average_daily_sunlight_hours); // the required energy during the daytime which is 8 hours
  // testing new method below, for nighttime energy calculation
  const required_daytime_kwh = meanDayWatts / 1000;

  // const required_nighttime_kwh = ((meanNightWatts / 1000) * (24-average_daily_sunlight_hours)); // the required energy during the nighttime which is 16 hours
  const required_nighttime_kwh = meanNightWatts / 1000;

  // Now we are using method from geoff system below
  // const solar_generated_kwh = (final_general_avg_kw * 24 * solar_array_sizing) / 100 ; 
  const solar_generated_kwh = (final_general_avg_kw * 24 * solar_array_sizing) / 100;


  //const battery_backup_kwh = system_results.battery_result.backup_power_provided * batteryCount; // old and i think correct way is to show what it makes
  const battery_backup_kwh = system_results.battery_result.backup_power_required; // this shows what is required


  // Calculate solar oversize graph data
  const solarOversize = solarOversizeGraph(required_daytime_kwh, required_nighttime_kwh, solar_generated_kwh, battery_backup_kwh
  );



  // Example calculation (replace with actual logic)
  const dailyEstimate = round(
    (pvWattsAcAnnual / 365) * solar_array_sizing,
    2
  );




  return {
    dailyEstimate,
    meanDayWatts,
    meanNightWatts,
    roofSize,
    solarOversize,

  };
};

// Helper functions

/**
 * Calculate daily use kWh based on hour readings and clamps
 * @param {Object[]} hourReadings - Array of hour readings.
 * @param {Object[]} clamps - Array of clamps.
 * @returns {number} The daily use in kWh.
 */
export const calculateDailyUseKwh = (hourReadings) => {
  // Sum the total_kwh for all hour readings
  const totalKwh = hourReadings.reduce((sum, reading) => {
    return sum + reading.total_kwh;
  }, 0);
  return totalKwh; // Already in kWh
};

/**
 * Calculate peak capacity covered based on panels and inverters
 * @param {Array} panels
 * @param {Array} inverters
 * @returns {number}
 */
export const calculatePeakCapacityCovered = (panels, inverters) => {
  // Example logic: Calculate the ratio of total panel capacity to total inverter capacity
  const totalPanelCapacity = panels.reduce((sum, panel) => sum + panel.WP, 0);
  const totalInverterCapacity = inverters.reduce((sum, inverter) => sum + inverter.rated_output, 0);
  return totalPanelCapacity / totalInverterCapacity;
};

/**
 * Calculate PV watts AC annual based on panels
 * @param {Array} panels
 * @returns {number}
 */
export const calculatePvWattsAcAnnual = (panels) => {
  // Example logic: Sum the annual output of all panels
  return panels.reduce((sum, panel) => sum + panel.WP * 365, 0); // Assuming 1 WP produces 1 watt-hour per day
};

/**
 * Calculate five-minute max readings based on hour readings
 * @param {Array} hourReadings
 * @returns {Array}
 */
// export const calculateFiveMinMaxReadings = (hourReadings) => {
//   // Example logic: Find the maximum reading over five-minute intervals
//   return hourReadings.map(reading => ({
//     time: reading.time,
//     max_clamp1i: reading.mean_clamp1i,
//     max_clamp1v: reading.mean_clamp1v,
//     max_clamp2i: reading.mean_clamp2i,
//     max_clamp2v: reading.mean_clamp2v,
//     max_clamp3i: reading.mean_clamp3i,
//     max_clamp3v: reading.mean_clamp3v,
//   }));
// };


export const localgetHours = (time) => {
  return new Date(time).getUTCHours();
};

/**
 * Calculate the mean watts per hour for day based on hour readings
 * @param {Array} hourReadings - Array of hour readings.
 * @returns {number} The mean watts per hour for the day.
 */
export const meanWattsPerHourFromReadingsForDay = (hourReadings) => {
  // Filter readings for day hours (8 AM to 4 PM)
  const filteredForDay = hourReadings.filter((reading) => {
    const hour = localgetHours(reading.time);
    console.log("HOUR DAY", hour);
    return hour >= 8 && hour <= 16;
  });

  // Calculate the mean of (mean_i * mean_v) for the filtered readings
  const totalMeanIAndV = filteredForDay.reduce((acc, reading) => {
    acc += reading.mean_i * reading.mean_v;
    return acc;
  }, 0);

  const totalmeanWattd = filteredForDay.reduce((acc, reading) => {
    acc += reading.mean_watt;
    return acc;
  }, 0);

  const meanOfMeanIAndV = totalMeanIAndV / filteredForDay.length;
  const meanWattd = totalmeanWattd / filteredForDay.length;
  console.log("MEAN OF MEAN I AND V", meanOfMeanIAndV);

  return meanWattd;
};



export const meanWattsPerHourFromReadingsForNight = (hourReadings) => {
  // Filter readings for day hours (8 AM to 4 PM)
  const filteredForDay = hourReadings.filter((reading) => {
    const hour = localgetHours(reading.time);
    console.log("HOUR DAY", hour);
    return !(hour >= 8 && hour <= 16);
  });

  // Calculate the mean of (mean_i * mean_v) for the filtered readings
  const totalMeanIAndV = filteredForDay.reduce((acc, reading) => {
    acc += reading.mean_i * reading.mean_v;
    return acc;
  }, 0);

  const totalmeanWattd = filteredForDay.reduce((acc, reading) => {
    acc += reading.mean_watt;
    return acc;
  }, 0);

  const meanOfMeanIAndV = totalMeanIAndV / filteredForDay.length;
  const meanWattd = totalmeanWattd / filteredForDay.length;
  console.log("MEAN OF MEAN I AND V", meanOfMeanIAndV);

  return meanWattd;
};

// export const maxWattsPerFiveMin = (fiveMinMaxReadings, clamps) => {
//   if (!fiveMinMaxReadings || !Array.isArray(fiveMinMaxReadings)) {
//     console.error("Invalid fiveMinMaxReadings data:", fiveMinMaxReadings);
//     return [];
//   }

//   if (!clamps || !Array.isArray(clamps)) {
//     console.error("Invalid clamps data:", clamps);
//     return [];
//   }

//   const mins = fiveMinMaxReadings.map((a) =>
//     a.max_clamp1v < 1000 && a.max_clamp2v < 1000 && a.max_clamp3v < 1000 // Ensure all clamp voltages are below threshold
//       ? a
//       : {
//         ...a,
//         max_clamp1i: 0,
//         max_clamp1v: 0,
//         max_clamp2i: 0,
//         max_clamp2v: 0,
//         max_clamp3i: 0,
//         max_clamp3v: 0,
//       }
//   );

//   const wattMins = mins
//     .map((a) => ({
//       date: new Date(a.time),
//       clamp1W: a.max_clamp1i && clamps[0]?.enabled ? a.max_clamp1i * a.max_clamp1v : 0,
//       clamp2W: a.max_clamp2i && clamps[1]?.enabled ? a.max_clamp2i * a.max_clamp2v : 0,
//       clamp3W: a.max_clamp3i && clamps[2]?.enabled ? a.max_clamp3i * a.max_clamp3v : 0,
//     }))
//     .map((a) => ({
//       ...a,
//       totalMaxWatt:
//         a.clamp1W * (clamps[0] && clamps[0].minus ? -1 : 1) +
//         a.clamp2W * (clamps[1] && clamps[1].minus ? -1 : 1) +
//         a.clamp3W * (clamps[2] && clamps[2].minus ? -1 : 1),
//     }));

//   return wattMins;
// };

// export const wattsPerHour = (hourReadings, clamps) => {
//   if (!hourReadings || !Array.isArray(hourReadings)) {
//     console.error("Invalid hourReadings data:", hourReadings);
//     return [];
//   }

//   if (!clamps || !Array.isArray(clamps)) {
//     console.error("Invalid clamps data:", clamps);
//     return [];
//   }

//   const hours = hourReadings.map((a) =>
//     a.mean_clamp1v < 1000
//       ? a
//       : {
//         ...a,
//         mean_clamp1i: 0,
//         mean_clamp1v: 0,
//         mean_clamp2i: 0,
//         mean_clamp2v: 0,
//         mean_clamp3i: 0,
//         mean_clamp3v: 0,
//       }
//   );

//   const wattHours = hours.map((a) => ({
//     date: new Date(a.time),
//     clamp1W: a.mean_clamp1i && clamps[0]?.enabled ? a.mean_clamp1i * a.mean_clamp1v : 0,
//     clamp2W: a.mean_clamp2i && clamps[1]?.enabled ? a.mean_clamp2i * a.mean_clamp2v : 0,
//     clamp3W: a.mean_clamp3i && clamps[2]?.enabled ? a.mean_clamp3i * a.mean_clamp3v : 0,
//     totalWatt:
//       a.mean_clamp1i * a.mean_clamp1v +
//       a.mean_clamp2i * a.mean_clamp2v +
//       a.mean_clamp3i * a.mean_clamp3v,
//   }));

//   return wattHours;
// };

export const roofSizeCalc = (panel, noPanels) => {
  return round((((panel.width / 1000) * panel.height) / 1000) * noPanels, 2);
};

export const solarOversizeGraph = (dayNeeded, nightNeeded, solarMade, batteryMade) => {
  return [
    {
      label: 'Loads',
      Day: round(dayNeeded, 2),
      Night: round(nightNeeded, 2),
    },
    {
      label: 'System',
      Solar: round(solarMade, 2),
      Battery: round(batteryMade, 2),
    },
  ];
};

// /**
//  * Calculate the weekly energy generation based on the panel specifications and number of panels.
//  * 
//  * @param {Object} panel - The panel object containing panel details.
//  * @param {number} panel.WP - The wattage of a single panel.
//  * @param {number} noPanels - The number of panels.
//  * @param {number} [sunlightHours=5.9] - The average daily sunlight hours. Defaults to 5.9 hours.
//  * 
//  * @returns {number} The weekly energy generation in kilowatt-hours (kWh).
//  */
// const calculateWeeklyGeneration = (panel, noPanels, sunlightHours = 5.9) => {
//   // Step 1: Total Watt-Peak (Wp) Calculation
//   const totalWp = panel.WP * noPanels;

//   // Step 2: Daily Generation Calculation
//   const dailyGenerationWh = totalWp * sunlightHours;

//   // Step 3: Convert to Kilowatt-Hours (kWh)
//   const dailyGenerationKwh = dailyGenerationWh / 1000;

//   // Step 4: Weekly Generation Calculation
//   const weeklyGenerationKwh = dailyGenerationKwh * 7;

//   return weeklyGenerationKwh;
// };



function roundTo(value, decimals) {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

// /**
//  * Convert a time string to a specific timezone.
//  * @param {string} timeStr - The time string to convert.
//  * @param {string} targetTimezone - The target timezone.
//  * @returns {string} The converted time string.
//  */
// const convertToTimezone = (timeStr, targetTimezone) => {
//   const date = new Date(timeStr);
//   const zonedDate = toZonedTime(date, targetTimezone);
//   return format(zonedDate, 'yyyy-MM-dd\'T\'HH:mm:ssXXX', { timeZone: targetTimezone });
// };



/**
 * Function to calculate weekly usage simulation.
 * 
 * @param {Object} sizingReport - The sizing report containing various parameters.
 * @param {number} sizingReport.dailyUseKwh - The daily energy usage in kWh.
 * @param {number} sizingReport.backupHours - The number of backup hours.
 * @param {number} sizingReport.peakCapacityCovered - The peak capacity covered.
 * @param {number} sizingReport.pvWattsAcAnnual - The annual AC output of the PV system in watts.
 * @param {number} sizingReport.batteryCapacityKwh - The battery capacity in kWh.
 * @param {number} sizingReport.inverterCapacityKw - The inverter capacity in kW.
 * @param {Object} sizingReport.panel - The panel object containing panel details.
 * @param {number} sizingReport.pvCount - The number of panels.
 * @param {Array} sizingReport.hourReadings - Array of hourly consumption data.
 * 
 * @returns {Object} The weekly usage simulation results with hourly data.
 */
export const calculateWeeklyUsageSimulation = (sizingReport) => {
  const {
    hourReadings, // Array of hourly consumption data
    inverterCapacityKw,
    panel,
    pvCount,
  } = sizingReport;

  // Format the raw hourly readings
  const formattedHourlyData = hourReadings.reduce((acc, reading) => {
    const date = dayjs(reading.time).format('YYYY-MM-DD');
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push({
      time: reading.time,
      totalWatt: reading.mean_watt / 1000 // total_power
    });
    // console.log("START Date", dayjs(reading.time).format('YYYY-MM-DD HH:mm:ss'), "value", roundTo(reading.mean_watt/1000,2));
    return acc;
  }, {});

  // Initialize weekly data arrays
  const weeklyData = Array.from({ length: 7 }, () => Array(24).fill(0));
  const countData = Array.from({ length: 7 }, () => Array(24).fill(0));

  // Populate each weekly day array with the totalWatt for each hour of the day
  const dates = Object.keys(formattedHourlyData);
  dates.forEach(date => {

    // Check what day of week that date is
    const dayOfWeek = dayjs(date).day(); // 0 (Sunday) to 6 (Saturday)
    const hourlyData = formattedHourlyData[date];

    if (hourlyData) {
      hourlyData.forEach(hourData => {
        const hour = dayjs(hourData.time).hour();
        // console.log("DIVE Date", dayjs(hourData.time).format('YYYY-MM-DD HH:mm:ss'), "value", hourData.totalWatt);
        weeklyData[dayOfWeek][hour] += hourData.totalWatt;
        countData[dayOfWeek][hour] += 1;
      });
    }
  });

  // console.log("finished weekly data", weeklyData);

  // Calculate averages
  const averageWeeklyData = weeklyData.map((dayData, dayIndex) => {
    return dayData.map((hourData, hourIndex) => {
      // console.log("SECOND Date", ['Sun', 'Mon', 'Tues', 'Wed', 'Th', 'Fri', 'Sat'][dayIndex], "index", countData[dayIndex][hourIndex], 'data', hourData, 'value', (hourData/countData[dayIndex][hourIndex]));
      return countData[dayIndex][hourIndex] > 0 ? roundTo((hourData / countData[dayIndex][hourIndex]), 2) : 0;
    });
  });







  // Generate labels and values for the chart
  const xLabels = [];
  for (let day = 0; day < 7; day++) {
    for (let hour = 0; hour < 24; hour++) {

      xLabels.push(`${['Sun', 'Mon', 'Tues', 'Wed', 'Th', 'Fri', 'Sat'][day]} ${hour}:00`);
    }
  }



  const yValues = averageWeeklyData.flat();

  const hourlyData = [];
  const pvGenerationKw = [];


  for (let i = 0; i < 7; i++) {
    for (let hour = 0; hour < 24; hour++) {
      // Generate the hour string in "HH:MM:SS" format
      const hourStr = `${String(hour + 1).padStart(2, '0')}:00:00`;
      const PvGenRate = getPvGeneration(hourStr, pv_generation_mapping);
      const hourlyPvGenerationKw = roundTo((PvGenRate * (panel.WP / 1000)) * pvCount, 1); // Adjust for number of panels

      const hourDataEntry = {
        day: i + 1,
        hour,
        hourlyUseKw: averageWeeklyData[i][hour], // Use average weekly data
        hourlyPvGenerationKw,
        inverterCapacityKw,
      };
      hourlyData.push(hourDataEntry);
      pvGenerationKw.push(hourlyPvGenerationKw);
    }
  }

  return {
    hourlyData,
    hoursOfWeek: xLabels,
    hourlyUseKw: yValues,
    pvGenerationKw,
  };
};


// Function to calculate estimated component costs
const calculateEstimatedComponentCosts = (sizingReport) => {
  const { inverters, panels } = sizingReport.components.details;
  const totalCost = [
    ...inverters.flatMap(inverter => [inverter.price, ...inverter.mppts.map(mppt => mppt.price)]),
    ...panels.map(panel => panel.price)
  ].reduce((total, price) => total + price, 0);
  return totalCost;
};

export const totalWithMarkup = (components) => {
  // Example implementation (replace with actual logic)
  const total = components.reduce((acc, component) => acc + component.cost, 0);
  const markup = 1.2; // Example markup value
  return round(total * markup, 2);
};

// Function to calculate component configuration
const calculateComponentConfiguration = (inverters, panels, panelCount) => {
  return {
    inverters: inverters.length,
    mppts: inverters.reduce((total, inverter) => total + inverter.mppts.length, 0),
    panels: panels.length,
    panelCount,
  };
};


export const CalculateComponentSetup = (component_config_details) => {
  // takes the components and determines if low or high voltage. hard coded for now
  // const inverterComp = {
  //   'rated_output': 12, //kW
  //   'price': 1000,
  //   'parallel_limit': 3,
  //   'max_input_voltage': 1000,
  //   'num_mppts': 4,
  //   'num_inputs_per_mppts': 2,
  //   'max_charge_per_mppts_isc': 55,
  //   'design_voltage': "HV",
  // }
  const inverterComp = component_config_details.inverterComponent;
  // const PVComp = {
  //   'name': "PV Panel",
  //   'make': "SunPower",
  //   'WP': 555,
  //   'VOC': 49.72,
  //   'VMP': 40.99,
  //   'ISC': 14.12,
  //   'temp_coefficient_VC': -0.0028,
  //   'price': 3000,
  //   'kW': 0.47, //kW
  //   'height_mm': 2182,
  //   'width_mm': 1029,
  //   'weight': 26.1
  // }
  const PVComp = component_config_details.pvComponent;


  // const batteryComp = {
  //   'kwh': 8,
  //   'price': 1000,
  //   'peak_kw': 4,
  //   'loss_factor': 0.15,
  //   'DoD': 290.816,
  //   'max_dod_percentage': 0.8,
  //   'design_voltage': "HV",
  //   'max_per_stack': 12,
  //   'bmu_needed': true,
  //   'balance_between_inverters': true,
  // }
  const batteryComp = component_config_details.batteryComponent;


  // console.log("PASSED GENERATED KW ", component_config_details.panel_generation_kw);

  const system_information = {
    'average_consumption_kW': component_config_details.overall_avg_kwh,
    'backup_hours': component_config_details.backup_hours,
    'peak_kw': component_config_details.overall_peak_watt / 1000,
    'panel_generation_kw': component_config_details.panel_generation_kw,
    // 'inverter_size_percent': 100,
    'pv_size_percent': component_config_details.pv_array_size,
    // 'battery_size_percent': 100,
    'number_of_inverters': component_config_details.inverterCount, // for three phase. pass through later
    'number_of_pv': component_config_details.pvCount, // passed later
    'number_of_batteries': component_config_details.batteryCount, // passed later
    'inverterComponent': inverterComp,
    'pvComponent': PVComp,
    'batteryComponent': batteryComp,
    'racks_per_inverter': component_config_details.racks_per_inverter,
    'initialInverterCount': component_config_details.initialInverterCount,
    'initialBatteryCount': component_config_details.initialBatteryCount,
    'initialPvCount': component_config_details.initialPvCount,
    'is_quick_sizing': component_config_details.is_quick_sizing,

    // 'dailySizingViewEstimate_kWh': {
    //   'solar_generation': component_config_details.solar_generation,
    //   'day_load': component_config_details.day_load,
    //   'battery_storage': component_config_details.battery_storage,
    //   'night_load': component_config_details.night_load,
    // }
  }

  // console.log("SYSTEM INFO", system_information);

  const resultt = inverterComp.design_voltage === "HV" ? CalculateHighVoltageSetup(system_information) : CalculateLowVoltageSetup(system_information);
  return resultt;
}


export const DeyeHVCalculation = (system_information) => {

  const INVERTER_DETAILS = {
    'SUN-25K-SG01HP3': { rated_power: 20, price: 56000 },
    'SUN-50K-SG01HP3': { rated_power: 50, price: 121000 }
  };


  const BATTERY_PRICE = 27300;
  const BMU_PRICE = 14300;
  const RACK_PRICE = 7800;
  const BATTERY_CAPACITY = 4.096;
  const MAX_BATTERIES_PER_INVERTER = 24;

  const peak_capacity = system_information.peak_kw;
  const backup_capacity = system_information.backup_hours * system_information.average_consumption_kW;

  // Calculate costs for each inverter model
  const calculateCosts = () => {
    const results = {};

    Object.entries(INVERTER_DETAILS).forEach(([model, details]) => {
      // Calculate inverters needed
      const inverters_needed = Math.ceil(peak_capacity / details.rated_power);

      // Calculate batteries
      const total_batteries_needed_initial = Math.ceil(backup_capacity / BATTERY_CAPACITY);
      const batteries_per_inverter = Math.min(
        MAX_BATTERIES_PER_INVERTER,
        Math.max(4, Math.ceil(total_batteries_needed_initial / inverters_needed))
      );
      const total_batteries_needed = batteries_per_inverter * inverters_needed;

      // Calculate racks and BMUs
      const racks_per_inverter = Math.ceil(batteries_per_inverter / 12);
      const total_racks_needed = racks_per_inverter * inverters_needed;
      const bmu_per_inverter = racks_per_inverter;
      const total_bmu_needed = bmu_per_inverter * inverters_needed;

      // Calculate costs
      const costs = {
        inverter_cost: inverters_needed * details.price,
        battery_cost: total_batteries_needed * BATTERY_PRICE,
        bmu_cost: total_bmu_needed * BMU_PRICE,
        rack_cost: total_racks_needed * RACK_PRICE
      };

      results[model] = {
        inverters_needed,
        batteries_per_inverter,
        total_batteries_needed,
        racks_per_inverter,
        total_racks_needed,
        bmu_per_inverter,
        total_bmu_needed,
        ...costs,
        total_cost: Object.values(costs).reduce((a, b) => a + b, 0)
      };
    });

    return results;
  };

  // Find most cost-effective option
  const findMostCostEffective = (results) => {
    return Object.entries(results).reduce((min, [model, details]) =>
      (!min || details.total_cost < min[1].total_cost) ? [model, details] : min
      , null);
  };

  const results = calculateCosts();
  const [model, details] = findMostCostEffective(results);

  return {
    model,
    ...details,
    calculations: results
  };
};


const CalculateHighVoltageSetup = (system_information) => {
  // System constants
  const PANEL_WATT_RATING = system_information.pvComponent.WP;
  const PANEL_VOC = system_information.pvComponent.VOC;
  const PANEL_VMP = system_information.pvComponent.VMP;
  const INVERTER_MAX_VOLTAGE = system_information.inverterComponent.max_input_voltage;
  const INVERTER_MIN_VOLTAGE = 150;
  const INPUTS_PER_MPPT = system_information.inverterComponent.num_inputs_per_mppts;
  const MPPT_COUNT = system_information.inverterComponent.num_mppts;
  const BATTERY_VOLTAGE = system_information.batteryComponent.voltage;
  const NUM_INVERTERS = Math.max(1, system_information.number_of_inverters);
  const BATTERY_COUNT = Math.max(1, (system_information.number_of_batteries/NUM_INVERTERS));
  const TOTAL_BATTERY_COUNT = system_information.number_of_batteries;
  const SOLAR_POWER_NEEDED_KW = system_information.panel_generation_kw;

  let INITIAL_INVERTER_COUNT = system_information.initialInverterCount;
  let INITIAL_BATTERY_COUNT = system_information.initialBatteryCount;
  // const INITIAL_PV_COUNT = system_information.initialPvCount;


  const RACKS_PER_INVERTER = system_information.racks_per_inverter;
  const MAX_BATTERIES_PER_INVERTER = 24;

  const IS_QUICK_SIZING = system_information.is_quick_sizing;

  if(IS_QUICK_SIZING){
    INITIAL_INVERTER_COUNT = 1;
    INITIAL_BATTERY_COUNT = 1;
  }
  

  console.log("Passed var - panel watt rating", PANEL_WATT_RATING);
  console.log("Passed var - panel VOC", PANEL_VOC);
  console.log("Passed var - panel VMP", PANEL_VMP);
  console.log("Passed var - inverter max voltage", INVERTER_MAX_VOLTAGE);
  console.log("Passed var - inverter min voltage", INVERTER_MIN_VOLTAGE);
  console.log("Passed var - inputs per mppt", INPUTS_PER_MPPT);
  console.log("Passed var - mppt count", MPPT_COUNT);
  console.log("Passed var - battery voltage", BATTERY_VOLTAGE);
  console.log("Passed var - num inverters", NUM_INVERTERS);
  console.log("Passed var - battery count", BATTERY_COUNT);
  console.log("Passed var - total battery count", TOTAL_BATTERY_COUNT);
  console.log("Passed var - solar power needed", SOLAR_POWER_NEEDED_KW);

  // Helper function for MPPT distribution
  const distributePanelsAcrossMPPTs = (totalPanels, stringSize, maxMpptCount, inputsPerMppt,minStringSize,maxStringSize  ) => {
    // console.log(`Target total panels: ${totalPanels}`);
    
    // Calculate minimum MPPTs needed
    const minStringsNeeded = Math.ceil(totalPanels / maxStringSize);
    const minMpptsNeeded = Math.ceil(minStringsNeeded / inputsPerMppt);
    const actualMpptCount = Math.min(minMpptsNeeded, maxMpptCount);
    
    console.log(`Using ${actualMpptCount} MPPTs out of ${maxMpptCount} available`);
    
    // Calculate target per MPPT
    const targetPanelsPerMppt = Math.ceil(totalPanels / actualMpptCount);
    
    let mppts = [];
    let totalConfiguredPanels = 0;
    let remainingPanels = totalPanels;
    
    // Distribute panels across active MPPTs
    for (let mppt = 1; mppt <= actualMpptCount && remainingPanels > 0; mppt++) {
        const panelsForThisMppt = Math.min(
            targetPanelsPerMppt,
            remainingPanels
        );
        
        // Calculate string configuration for this MPPT
        const stringsNeeded = Math.ceil(panelsForThisMppt / maxStringSize);
        const optimalStringSize = Math.ceil(panelsForThisMppt / stringsNeeded);
        const finalStringSize = Math.min(Math.max(optimalStringSize, minStringSize), maxStringSize);
        
        const stringsForThisMppt = Math.min(
            Math.ceil(panelsForThisMppt / finalStringSize),
            inputsPerMppt
        );
        
        const actualPanelsInMppt = stringsForThisMppt * finalStringSize;
        totalConfiguredPanels += actualPanelsInMppt;
        remainingPanels -= actualPanelsInMppt;
        
        mppts.push({
            mppt,
            strings: Array(stringsForThisMppt).fill(finalStringSize)
        });
    }
    
    console.log(`Target vs Actual: ${totalPanels} -> ${totalConfiguredPanels}`);
    return {
        mppts, 
        totalConfigured: totalConfiguredPanels
    };
};

  // Calculate panel requirements
  const panelPowerKw = PANEL_WATT_RATING / 1000;
  let theoreticalPanelsNeeded = Math.ceil(SOLAR_POWER_NEEDED_KW / panelPowerKw);

  // Calculate string size limits
  const minStringSizeFromPv = Math.ceil(INVERTER_MIN_VOLTAGE / PANEL_VMP);

  const batteries_per_inverter = Math.min(MAX_BATTERIES_PER_INVERTER,
    Math.max(4, Math.ceil(BATTERY_COUNT)))


  const batteries_per_rack = Math.ceil(batteries_per_inverter / RACKS_PER_INVERTER);
  const battery_bank_voltage = BATTERY_VOLTAGE * batteries_per_rack
  const minStringSizeFromBattery = Math.ceil(battery_bank_voltage / PANEL_VMP);
  const minStringSize = Math.max(minStringSizeFromPv, minStringSizeFromBattery);
  const maxStringSize = Math.floor((INVERTER_MAX_VOLTAGE * 0.85) / PANEL_VOC);

  const Initialbatteries_per_inverter = Math.min(MAX_BATTERIES_PER_INVERTER,Math.max(4, Math.ceil(INITIAL_BATTERY_COUNT / INITIAL_INVERTER_COUNT)))
    const Initialbatteries_per_rack = Math.ceil(Initialbatteries_per_inverter / RACKS_PER_INVERTER);
    const Initialbattery_bank_voltage = BATTERY_VOLTAGE * Initialbatteries_per_rack
    const InitialminStringSizeFromBattery = Math.ceil(Initialbattery_bank_voltage / PANEL_VMP);
    const InitialminStringSize = Math.max(minStringSizeFromPv, InitialminStringSizeFromBattery);
    const InitialmaxStringSize = Math.floor((INVERTER_MAX_VOLTAGE * 0.85) / PANEL_VOC);
  


    if (minStringSize > maxStringSize) {
    console.error("Invalid string size range:", { minStringSize, maxStringSize });
    const emptyConfig = [{
      inverter: 1,
      mppts: [],
      batteries: 0,
      inverter_details: {
        max_panels_per_string: maxStringSize,
        min_panels_per_string: minStringSize,
        max_strings_per_mppt: INPUTS_PER_MPPT,
        optimal_string_size: 0,
        optimal_strings_per_inverter: 0,
        optimal_extra_panels: 0,
        optimal_total_panels: 0
      }
    }];

    return {
      optimal: emptyConfig,
      actual: emptyConfig,
      totals: {
        optimalInverters: 0,
        optimalPanels: 0,
        optimalBatteries: 0,
        actualPanels: 0,
        actualBatteries: 0
      },
      state:"Error: Invalid String Size Range: " + JSON.stringify({ minStringSize, maxStringSize} + ". Please check your system configuration and try again.")
    };
}

  // Calculate system limits actual
  const maxStringsPerInverter = MPPT_COUNT * INPUTS_PER_MPPT;
  const maxTotalStrings = maxStringsPerInverter * NUM_INVERTERS;
  const maxAllowablePanels = maxTotalStrings * maxStringSize;
  let totalPanelsNeeded = Math.min(theoreticalPanelsNeeded, maxAllowablePanels);

    // Calculate system limits Initial
    const InitialmaxStringsPerInverter = MPPT_COUNT * INPUTS_PER_MPPT;
    const InitialmaxTotalStrings = InitialmaxStringsPerInverter * INITIAL_INVERTER_COUNT;
    const InitialmaxAllowablePanels = InitialmaxTotalStrings * InitialmaxStringSize;
    let InitialtotalPanelsNeeded = Math.min(theoreticalPanelsNeeded, InitialmaxAllowablePanels);
  

  // Find optimal configuration
  const panelsPerInverter = Math.ceil(totalPanelsNeeded / NUM_INVERTERS);
  let bestConfig = null;
  let closestToZeroResidual = Infinity;

  const InitialpanelsPerInverter = Math.ceil(InitialtotalPanelsNeeded / INITIAL_INVERTER_COUNT);
  let InitialbestConfig = null;
  let InitialclosestToZeroResidual = Infinity;

  for (let stringSize = minStringSize; stringSize <= maxStringSize; stringSize++) {
    const stringsPerInverter = Math.ceil(panelsPerInverter / stringSize);
    if (stringsPerInverter > maxStringsPerInverter) continue;

    const totalPanelsWithConfig = stringsPerInverter * stringSize * NUM_INVERTERS;
    const residual = totalPanelsWithConfig - totalPanelsNeeded;

    if (Math.abs(residual) < Math.abs(closestToZeroResidual)) {
      closestToZeroResidual = residual;
      bestConfig = {
        stringSize,
        stringsPerInverter,
        totalPanels: totalPanelsWithConfig,
        residualPanels: residual
      };
    }
  }

  // do best initial HV 
  for (let stringSize = InitialminStringSize; stringSize <= InitialmaxStringSize; stringSize++) {
    const InitialstringsPerInverter = Math.ceil(InitialpanelsPerInverter / stringSize);
    if (InitialstringsPerInverter > InitialmaxStringsPerInverter) continue;

    const InitialtotalPanelsWithConfig = InitialstringsPerInverter * stringSize * INITIAL_INVERTER_COUNT;
    console.log("intial inverter count",INITIAL_INVERTER_COUNT);
    console.log("THE INITIAL HV PANELS", InitialtotalPanelsWithConfig);
    const Initialresidual = InitialtotalPanelsWithConfig - InitialtotalPanelsNeeded;

    if (Math.abs(Initialresidual) < Math.abs(InitialclosestToZeroResidual)) {
      InitialclosestToZeroResidual = Initialresidual;
      InitialbestConfig = {
        stringSize,
        InitialstringsPerInverter,
        totalPanels: InitialtotalPanelsWithConfig,
        residualPanels: Initialresidual
      };
    }
  }

  if (!bestConfig) {
    console.error("No valid configuration found");
    const emptyConfig = [{
        inverter: 1,
        mppts: [],
        batteries: 0,
        inverter_details: {
            max_panels_per_string: maxStringSize,
            min_panels_per_string: minStringSize,
            max_strings_per_mppt: INPUTS_PER_MPPT,
            optimal_string_size: 0,
            optimal_strings_per_inverter: 0,
            optimal_extra_panels: 0,
            optimal_total_panels: 0
        }
    }];

    return {
        optimal: emptyConfig,
        actual: emptyConfig,
        totals: {
            optimalInverters: 0,
            optimalPanels: 0, 
            optimalBatteries: 0,
            actualPanels: 0,
            actualBatteries: 0
        },
         state: "Error: No Valid Configuration Found, Could not find a valid configuration for the given system parameters"
    };
}





  // Generate optimal configuration
  const optimalConfig = Array(INITIAL_INVERTER_COUNT).fill().map((_, i) => {
    const mpptResult = distributePanelsAcrossMPPTs(
      // Math.ceil(totalPanelsNeeded / NUM_INVERTERS),
      Math.ceil(InitialbestConfig.totalPanels / INITIAL_INVERTER_COUNT),
      InitialbestConfig.stringSize,
      MPPT_COUNT,
      INPUTS_PER_MPPT,
      InitialminStringSize,
      InitialmaxStringSize
    );
    
    return {
      inverter: i + 1,
      mppts: mpptResult.mppts,
      batteries: Math.ceil(INITIAL_BATTERY_COUNT/INITIAL_INVERTER_COUNT),
      inverter_details: {
      max_panels_per_string: InitialmaxStringSize,
      min_panels_per_string: InitialminStringSize,
      max_strings_per_mppt: INPUTS_PER_MPPT,
      optimal_string_size: InitialbestConfig.stringSize,
      optimal_strings_per_inverter: InitialbestConfig.stringsPerInverter,
      optimal_extra_panels: InitialbestConfig.residualPanels,
      optimal_total_panels: InitialbestConfig.totalPanels,
      optimal_alt_panels: InitialtotalPanelsNeeded,
      target_panels: Math.ceil(InitialtotalPanelsNeeded / INITIAL_INVERTER_COUNT),
      configured_panels: mpptResult.totalConfigured,
      target_batteries: Math.ceil(INITIAL_BATTERY_COUNT/INITIAL_INVERTER_COUNT),
    }
  };
});


  // Generate actual configuration
  const actualConfig = Array(NUM_INVERTERS).fill().map((_, i) => {
    const mpptResult = distributePanelsAcrossMPPTs(
      Math.ceil(system_information.number_of_pv / NUM_INVERTERS), // Use actual panel count
      bestConfig.stringSize,
      MPPT_COUNT,
      INPUTS_PER_MPPT,
      minStringSize,
      maxStringSize
    );

    return {
      inverter: i + 1,
      mppts: mpptResult.mppts,
      batteries: Math.ceil(BATTERY_COUNT),
      inverter_details: {
        max_panels_per_string: maxStringSize,
        min_panels_per_string: minStringSize,
        max_strings_per_mppt: INPUTS_PER_MPPT,
        actual_string_size: bestConfig.stringSize,
        actual_strings_per_inverter: Math.ceil(system_information.number_of_pv / NUM_INVERTERS / bestConfig.stringSize),
        total_panels: system_information.number_of_pv,
        target_panels: Math.ceil(system_information.number_of_pv / NUM_INVERTERS), // Use actual panel count
        configured_panels: mpptResult.totalConfigured,
        target_batteries: TOTAL_BATTERY_COUNT,
        total_balanced_batteries: Math.ceil(BATTERY_COUNT)*NUM_INVERTERS,
      }
    };
});

  return {
    optimal: optimalConfig,
    actual: actualConfig,
    totals: {
      optimalInverters: NUM_INVERTERS,
      optimalPanels: bestConfig.totalPanels,
      optimalBatteries: roundTo(BATTERY_COUNT, 0) * NUM_INVERTERS,
      actualPanels: system_information.number_of_pv,
      actualBatteries: TOTAL_BATTERY_COUNT
    },
    state: ""
  };
};




const CalculateLowVoltageSetup = (system_information) => {
  // LV Constants
  const PANEL_WATT_RATING = system_information.pvComponent.WP;
  const PANEL_VOC = system_information.pvComponent.VOC;
  const PANEL_VMP = system_information.pvComponent.VMP;
  const INVERTER_MAX_VOLTAGE = system_information.inverterComponent.max_input_voltage;
  const INVERTER_MIN_VOLTAGE = 150;
  const INPUTS_PER_MPPT = system_information.inverterComponent.num_inputs_per_mppts;
  const MPPT_COUNT = system_information.inverterComponent.num_mppts;
  const NUM_INVERTERS = Math.max(1, system_information.number_of_inverters);
  const BATTERY_COUNT = Math.max(1, system_information.number_of_batteries);
  let TOTAL_BATTERY_COUNT = Math.max(1, system_information.number_of_batteries);
  const SOLAR_POWER_NEEDED_KW = system_information.panel_generation_kw;

  let INITIAL_INVERTER_COUNT = system_information.initialInverterCount;
  let INITIAL_BATTERY_COUNT = system_information.initialBatteryCount;

  // const IS_QUICK_SIZING = system_information.is_quick_sizing;

 


  // const INITIAL_PV_COUNT = system_information.initialPvCount;

  // LV-specific helper for MPPT distribution
  const distributePanelsAcrossMPPTs = (totalPanels, stringSize, maxMpptCount, inputsPerMppt) => {
    // console.log(`Target total panels: ${totalPanels}`);
    
    // LV specific - Calculate minimum MPPTs based on minimum voltage requirements
    const minStringsNeeded = Math.ceil(totalPanels / maxStringSize);
    const minMpptsNeeded = Math.ceil(minStringsNeeded / inputsPerMppt);
    const actualMpptCount = Math.min(minMpptsNeeded, maxMpptCount);
    
    // console.log(`Using ${actualMpptCount} MPPTs out of ${maxMpptCount} available`);
    
    // LV specific - Calculate string size that meets voltage requirements and maximizes string size
    const targetPanelsPerMppt = Math.ceil(totalPanels / actualMpptCount);
    const stringsNeeded = Math.ceil(targetPanelsPerMppt / maxStringSize);
    const optimalStringSize = Math.min(
        maxStringSize,
        Math.max(
            Math.ceil(targetPanelsPerMppt / stringsNeeded),
            Math.ceil(INVERTER_MIN_VOLTAGE / PANEL_VMP)  // LV specific - ensure minimum voltage
        )
    );
    
    let mppts = [];
    let totalConfiguredPanels = 0;
    let remainingPanels = totalPanels;
    
    // LV specific - Distribute panels ensuring minimum voltage requirements
    for (let mppt = 1; mppt <= actualMpptCount && remainingPanels > 0; mppt++) {
        const isLastMppt = mppt === actualMpptCount;
        const panelsForThisMppt = isLastMppt ? 
            remainingPanels : 
            Math.min(targetPanelsPerMppt, remainingPanels);
            
        // Calculate strings needed for this MPPT
        const stringsForThisMppt = Math.min(
            Math.ceil(panelsForThisMppt / optimalStringSize),
            inputsPerMppt
        );
        
        // Allow string size up to maxStringSize while ensuring minimum voltage
        const finalStringSize = Math.min(
            maxStringSize,
            Math.max(
                Math.ceil(panelsForThisMppt / stringsForThisMppt),
                Math.ceil(INVERTER_MIN_VOLTAGE / PANEL_VMP)
            )
        );
        
        const actualPanelsInMppt = stringsForThisMppt * finalStringSize;
        totalConfiguredPanels += actualPanelsInMppt;
        remainingPanels -= actualPanelsInMppt;
        
        mppts.push({
            mppt,
            strings: Array(stringsForThisMppt).fill(finalStringSize)
        });
    }
    
    // console.log(`Target vs Actual: ${totalPanels} -> ${totalConfiguredPanels}`);
    return {
        mppts,
        totalConfigured: totalConfiguredPanels
    };
};

    // Calculate panel requirements
    const panelPowerKw = PANEL_WATT_RATING / 1000;
    let theoreticalPanelsNeeded = Math.ceil(SOLAR_POWER_NEEDED_KW / panelPowerKw);

    // LV string size limits
    const minStringSize = Math.ceil(INVERTER_MIN_VOLTAGE / PANEL_VMP);
    const maxStringSize = Math.floor((INVERTER_MAX_VOLTAGE * 0.85) / PANEL_VOC);

    // Error handling for invalid string size range
    if (minStringSize > maxStringSize) {
      console.error("Invalid string size range:", { minStringSize, maxStringSize });
      const emptyConfig = [{
          inverter: 1,
          mppts: [],
          batteries: 0,
          inverter_details: {
              max_panels_per_string: maxStringSize,
              min_panels_per_string: minStringSize,
              max_strings_per_mppt: INPUTS_PER_MPPT,
              optimal_string_size: 0,
              optimal_strings_per_inverter: 0,
              optimal_extra_panels: 0,
              optimal_total_panels: 0
          }
      }];

      return {
          optimal: emptyConfig,
          actual: emptyConfig,
          totals: {
              optimalInverters: 0,
              optimalPanels: 0,
              optimalBatteries: 0,
              actualPanels: 0,
              actualBatteries: 0
          },
          state: "Error: Invalid String Size Range: " + JSON.stringify({ minStringSize, maxStringSize } + ". Please check your system configuration and try again.")
      };
  }



    // System limits
    const maxStringsPerInverter = MPPT_COUNT * INPUTS_PER_MPPT;
    const maxAllowablePanels = maxStringsPerInverter * maxStringSize * NUM_INVERTERS;
    let totalPanelsNeeded = Math.min(theoreticalPanelsNeeded, maxAllowablePanels);

    const InitialmaxStringsPerInverter = MPPT_COUNT * INPUTS_PER_MPPT;
    const InitialmaxAllowablePanels = InitialmaxStringsPerInverter * maxStringSize * INITIAL_INVERTER_COUNT;
    let InitialtotalPanelsNeeded = Math.min(theoreticalPanelsNeeded, InitialmaxAllowablePanels);


    console.log("Theoretical panels needed:", theoreticalPanelsNeeded);
    console.log("InitialtotalPanelsNeeded", InitialtotalPanelsNeeded);

    // Find optimal configuration
    const panelsPerInverter = Math.ceil(totalPanelsNeeded / NUM_INVERTERS);
    let bestConfig = null;
    let closestToZeroResidual = Infinity;


    const InitialpanelsPerInverter = Math.ceil(InitialtotalPanelsNeeded / INITIAL_INVERTER_COUNT);
    let InitialbestConfig = null;
    let InitialclosestToZeroResidual = Infinity;


// Find best LV configuration
for (let stringSize = minStringSize; stringSize <= maxStringSize; stringSize++) {
  const stringsPerInverter = Math.ceil(panelsPerInverter / stringSize);
  if (stringsPerInverter > maxStringsPerInverter) continue;

  const totalPanelsWithConfig = stringsPerInverter * stringSize * NUM_INVERTERS;
  
  // console.log("total panels with config", totalPanelsWithConfig);
  // console.log("maxAllowablePanels", maxAllowablePanels);
  // Skip if configuration exceeds system limits
  if (totalPanelsWithConfig > maxAllowablePanels) continue;
  
  const residual = totalPanelsWithConfig - Math.min(totalPanelsNeeded, maxAllowablePanels);

  // LV specific check for minimum voltage requirements and system limits
  if (stringSize * PANEL_VMP >= INVERTER_MIN_VOLTAGE && 
      Math.abs(residual) < Math.abs(closestToZeroResidual)) {
      closestToZeroResidual = residual;
      bestConfig = {
          stringSize,
          stringsPerInverter,
          totalPanels: totalPanelsWithConfig,
          residualPanels: residual
      };
  }
}

// Find best LV configuration for initial
for (let stringSize = minStringSize; stringSize <= maxStringSize; stringSize++) {
  const InitialstringsPerInverter = Math.ceil(InitialpanelsPerInverter / stringSize);
  if (InitialstringsPerInverter > InitialmaxStringsPerInverter) continue;

  const InitialtotalPanelsWithConfig = InitialstringsPerInverter * stringSize * INITIAL_INVERTER_COUNT;

  // Skip if configuration exceeds system limits
  if (InitialtotalPanelsWithConfig > InitialmaxAllowablePanels) continue;
  
  const Initialresidual = InitialtotalPanelsWithConfig - Math.min(InitialtotalPanelsNeeded, InitialmaxAllowablePanels);

  // LV specific check for minimum voltage requirements and system limits
  if (stringSize * PANEL_VMP >= INVERTER_MIN_VOLTAGE && 
      Math.abs(Initialresidual) < Math.abs(InitialclosestToZeroResidual)) {
      InitialclosestToZeroResidual = Initialresidual;
      InitialbestConfig = {
          stringSize,
          InitialstringsPerInverter,
          totalPanels: InitialtotalPanelsWithConfig,
          residualPanels: Initialresidual
      };
  }
}

if (!bestConfig) {
  console.error("No valid configuration found");
  const emptyConfig = [{
      inverter: 1,
      mppts: [],
      batteries: 0,
      inverter_details: {
          max_panels_per_string: maxStringSize,
          min_panels_per_string: minStringSize,
          max_strings_per_mppt: INPUTS_PER_MPPT,
          optimal_string_size: 0,
          optimal_strings_per_inverter: 0,
          optimal_extra_panels: 0,
          optimal_total_panels: 0
      }
  }];

  return {
      optimal: emptyConfig,
      actual: emptyConfig,
      totals: {
        optimalInverters: 0,
        optimalPanels: 0,
        optimalBatteries: 0,
        actualPanels: 0,
        actualBatteries: 0,
      },
      state: "Error: No Valid Configuration Found, Could not find a valid configuration for the given system parameters"
  };
}

// console.log("total panels::::", InitialbestConfig.totalPanels);
// console.log("INITIAL_INVERTER_COUNT", INITIAL_INVERTER_COUNT);
// console.log("string sizeee", InitialbestConfig.stringSize)

// Generate LV optimal configuration
const optimalConfig = Array(INITIAL_INVERTER_COUNT).fill().map((_, i) => {
  const mpptResult = distributePanelsAcrossMPPTs(
     //Math.ceil(InitialbestConfig.totalPanels / INITIAL_INVERTER_COUNT),
     Math.ceil(InitialbestConfig.totalPanels / INITIAL_INVERTER_COUNT),
     InitialbestConfig.stringSize,
      MPPT_COUNT,
      INPUTS_PER_MPPT
  );
  
  return {
      inverter: i + 1,
      mppts: mpptResult.mppts,
      batteries: Math.ceil(INITIAL_BATTERY_COUNT / INITIAL_INVERTER_COUNT),
      inverter_details: {
          max_panels_per_string: maxStringSize,
          min_panels_per_string: minStringSize,
          max_strings_per_mppt: INPUTS_PER_MPPT,
          optimal_string_size: InitialbestConfig.stringSize,
          optimal_strings_per_inverter: InitialbestConfig.stringsPerInverter,
          optimal_extra_panels: InitialbestConfig.residualPanels,
          optimal_total_panels: InitialbestConfig.totalPanels,
          target_panels: Math.ceil(totalPanelsNeeded / INITIAL_INVERTER_COUNT),
          optimal_alt_panels: totalPanelsNeeded,
          configured_panels: mpptResult.totalConfigured,
          min_voltage: InitialbestConfig.stringSize * PANEL_VMP,
          max_voltage: InitialbestConfig.stringSize * PANEL_VOC
      }
  };
});

// Generate LV actual configuration
const actualConfig = Array(NUM_INVERTERS).fill().map((_, i) => {
  const mpptResult = distributePanelsAcrossMPPTs(
      Math.ceil(system_information.number_of_pv / NUM_INVERTERS),
      bestConfig.stringSize,
      MPPT_COUNT,
      INPUTS_PER_MPPT
  );

  let total_balanced_batteries_value = Math.ceil(BATTERY_COUNT / NUM_INVERTERS)*NUM_INVERTERS;
  // if(IS_QUICK_SIZING){
    
  // }

  return {
      inverter: i + 1,
      mppts: mpptResult.mppts,
      batteries: Math.ceil(BATTERY_COUNT / NUM_INVERTERS),
      inverter_details: {
          max_panels_per_string: maxStringSize,
          min_panels_per_string: minStringSize,
          max_strings_per_mppt: INPUTS_PER_MPPT,
          actual_string_size: bestConfig.stringSize,
          actual_strings_per_inverter: Math.ceil(system_information.number_of_pv / NUM_INVERTERS / bestConfig.stringSize),
          total_panels: system_information.number_of_pv,
          target_panels: Math.ceil(system_information.number_of_pv / NUM_INVERTERS),
          configured_panels: mpptResult.totalConfigured,
          min_voltage: bestConfig.stringSize * PANEL_VMP,
          max_voltage: bestConfig.stringSize * PANEL_VOC,
          target_batteries: TOTAL_BATTERY_COUNT,
          total_balanced_batteries: total_balanced_batteries_value,
      }
  };
});

  return {
    optimal: optimalConfig,
    actual: actualConfig,
    totals: {
      optimalInverters: NUM_INVERTERS,
      optimalPanels: bestConfig.totalPanels,
      optimalBatteries: BATTERY_COUNT,
      actualPanels: system_information.number_of_pv,
      actualBatteries: BATTERY_COUNT
    },
    state: ""
  };
};

// const CalculateLowVoltageSetup = (system_information) => {
//   // Constants from Python
//   const PANEL_WATT_RATING = system_information.pvComponent.WP;//555;  // Watts
//   const PANEL_VOC = system_information.pvComponent.VOC;//49;           // Open-circuit voltage
//   const PANEL_VMP = system_information.pvComponent.VMP;//40;           // Voltage at maximum power

//   console.log("passed - panel watt rating", system_information.pvComponent.WP);
//   console.log("passed - panel voc", system_information.pvComponent.VOC);
//   console.log("passed - panel vmp", system_information.pvComponent.VMP);

//   const INVERTER_MAX_VOLTAGE = system_information.inverterComponent.max_input_voltage; // Max voltage
//   const INVERTER_MIN_VOLTAGE = 150;
//   const INPUTS_PER_MPPT = system_information.inverterComponent.num_inputs_per_mppts;
//   const MPPT_COUNT = system_information.inverterComponent.num_mppts;

//   const NUM_INVERTERS = system_information.number_of_inverters;
//   const SOLAR_POWER_NEEDED_KW = system_information.panel_generation_kw;//system_information.required_power || 55.95;


//   const BATTERY_COUNT = system_information.number_of_batteries;


//   console.log("passed - INVERTER_MAX_VOLTAGE", system_information.inverterComponent.max_input_voltage);
//   console.log("passed - INPUTS_PER_MPPT", system_information.inverterComponent.num_inputs_per_mppts);
//   console.log("passed - MPPT_COUNT", system_information.inverterComponent.num_mppts);
//   console.log("passed - BATTERY_COUNT", system_information.number_of_batteries);
//   console.log("passed - BATTERY_VOLTAGE", system_information.batteryComponent.voltage);
//   console.log("passed - NUM INVERTERS", system_information.number_of_inverters);

//   // const PANEL_COUNT = system_information.number_of_pv;


//   console.log("passed - battery count", system_information.number_of_batteries);

//   let total_panels_needed = Math.ceil(SOLAR_POWER_NEEDED_KW / (PANEL_WATT_RATING / 1000));
//   let total_panels_needed_optimal = Math.ceil(SOLAR_POWER_NEEDED_KW / (PANEL_WATT_RATING / 1000));


//   const min_string_size = Math.ceil(INVERTER_MIN_VOLTAGE / PANEL_VMP);
//   const max_string_size = Math.floor((INVERTER_MAX_VOLTAGE * 0.85) / PANEL_VOC);

//   if (min_string_size > max_string_size) {
//     console.error("Invalid string size range:", { min_string_size, max_string_size });
//     const emptyConfig = [{
//       inverter: 1,
//       mppts: [],
//       batteries: 0,
//       inverter_details: {
//         max_panels_per_string: max_string_size,
//         min_panels_per_string: min_string_size,
//         max_strings_per_mppt: INPUTS_PER_MPPT,
//         optimal_string_size: 0,
//         optimal_strings_per_inverter: 0,
//         optimal_extra_panels: 0,
//         optimal_total_panels: 0
//       }
//     }];

//     return {
//       optimal: emptyConfig,
//       actual: emptyConfig,
//       totals: {
//         optimalInverters: 0,
//         optimalPanels: 0,
//         optimalBatteries: 0,
//         actualPanels: 0,
//         actualBatteries: 0
//       },
//       state:"Error: Invalid String Size Range: " + JSON.stringify({ min_string_size, max_string_size})
//     };
//   }
//   // Rest matches HV but without battery calculations
//   const max_strings_per_inverter = MPPT_COUNT * INPUTS_PER_MPPT;
//   const max_total_strings = max_strings_per_inverter * NUM_INVERTERS;
//   const max_allowable_panels = max_total_strings * max_string_size;

//   // Step 4: Calculate total number of strings needed
//   let total_strings_needed = Math.ceil(total_panels_needed / min_string_size);
//   let total_strings_needed_optimal = Math.ceil(total_panels_needed_optimal / min_string_size);

//   // Check if total panels needed exceeds max allowable
//   if (total_panels_needed > max_allowable_panels) {
//     total_panels_needed = max_allowable_panels;
//   }

//   // Check if total panels needed exceeds max allowable
//   if (total_panels_needed_optimal > max_allowable_panels) {
//     total_panels_needed_optimal = max_allowable_panels;
//   }

//   // Calculate panels per inverter
//   const panels_per_inverter = total_panels_needed / NUM_INVERTERS;
//   let best_config = null;
//   let closest_to_zero_residual = Infinity;

//   // Find optimal string configuration
//   for (let string_size = min_string_size; string_size <= max_string_size; string_size++) {
//     const strings_per_inverter = Math.ceil(panels_per_inverter / string_size);

//     // Skip if exceeds max strings per inverter
//     if (strings_per_inverter > max_strings_per_inverter) {
//       continue;
//     }

//     const total_panels_with_this_config = strings_per_inverter * string_size * NUM_INVERTERS;
//     const residual = total_panels_with_this_config - total_panels_needed;

//     if (Math.abs(residual) < Math.abs(closest_to_zero_residual)) {
//       closest_to_zero_residual = residual;
//       best_config = {
//         string_size,
//         strings_per_inverter,
//         total_panels: total_panels_with_this_config,
//         residual_panels: residual
//       };
//     }
//   }

//   console.log("Optimal Configuration:", {
//     panels_per_string: best_config.string_size,
//     strings_per_inverter: best_config.strings_per_inverter,
//     total_panels: best_config.total_panels,
//     extra_panels: best_config.residual_panels
//   });

//   // Distribute strings among inverters
//   let config = [];

//   // const total_system_strings = best_config.strings_per_inverter * NUM_INVERTERS;

//   for (let inverter = 1; inverter <= system_information.number_of_inverters; inverter++) {
//     let mppts = [];
//     let remaining_strings = best_config.strings_per_inverter; // Reset for each inverter
//     let batteries_for_inverter = Math.min(BATTERY_COUNT, BATTERY_COUNT / system_information.number_of_inverters);

//     // Calculate strings per MPPT for this inverter
//     const strings_per_mppt = Math.ceil(remaining_strings / MPPT_COUNT);

//     // Distribute strings across MPPTs
//     for (let mppt = 1; mppt <= MPPT_COUNT; mppt++) {
//       let strings_for_this_mppt = mppt === MPPT_COUNT ?
//         remaining_strings :
//         Math.min(strings_per_mppt, remaining_strings);

//       if (strings_for_this_mppt > 0) {
//         mppts.push({
//           mppt,
//           strings: Array(strings_for_this_mppt).fill(best_config.string_size)
//         });
//         remaining_strings -= strings_for_this_mppt;
//       }
//     }

//     const inverter_details = {
//       max_panels_per_string: max_string_size,
//       min_panels_per_string: min_string_size,
//       max_strings_per_mppt: INPUTS_PER_MPPT,
//       optimal_string_size: best_config.string_size,
//       optimal_strings_per_inverter: best_config.strings_per_inverter,
//       optimal_extra_panels: best_config.residual_panels,
//       optimal_total_panels: best_config.total_panels
//     };

//     config.push({
//       inverter,
//       mppts,
//       batteries: batteries_for_inverter,
//       inverter_details
//     });


//   }

//   console.log("result - total panels needed", total_panels_needed);
//   console.log("result - minumum string size", min_string_size);
//   console.log("result - maximum string size", max_string_size);
//   console.log("result - Maximum allowable panels", max_allowable_panels);
//   console.log("result - optimal string size", total_strings_needed_optimal)
//   console.log("result - string size", total_strings_needed)
//   console.log("result - best string size", best_config.string_size);
//   console.log("result - best strings per inverter", best_config.strings_per_inverter);
//   console.log("result - total panels with this config", best_config.total_panels);
//   console.log("result - residual panels", best_config.residual_panels);


//   // Calculate actual configuration
//   const calculateActualConfig = () => {
//     let actual_config = [];
//     const actual_panels = system_information.number_of_pv;
//     const actual_panels_per_inverter = Math.ceil(actual_panels / NUM_INVERTERS);
//     const actual_string_size = Math.min(
//       Math.floor(actual_panels_per_inverter / max_strings_per_inverter),
//       max_string_size
//     );

//     for (let inverter = 1; inverter <= NUM_INVERTERS; inverter++) {
//       let mppts = [];
//       let remaining_panels = actual_panels_per_inverter;
//       let remaining_strings = Math.min(
//         Math.ceil(remaining_panels / actual_string_size),
//         MPPT_COUNT * INPUTS_PER_MPPT
//       );
//       let batteries_for_inverter = Math.min(BATTERY_COUNT, BATTERY_COUNT / NUM_INVERTERS);

//       // Calculate max allowed strings per MPPT
//       const strings_per_mppt = Math.min(
//         Math.ceil(remaining_strings / MPPT_COUNT),
//         INPUTS_PER_MPPT
//       );

//       // Distribute strings across MPPTs
//       for (let mppt = 1; mppt <= MPPT_COUNT && remaining_strings > 0; mppt++) {
//         let strings_for_this_mppt = Math.min(
//           mppt === MPPT_COUNT ? remaining_strings : strings_per_mppt,
//           INPUTS_PER_MPPT,
//           remaining_strings
//         );

//         if (strings_for_this_mppt > 0) {
//           mppts.push({
//             mppt,
//             strings: Array(strings_for_this_mppt).fill(actual_string_size)
//           });
//           remaining_strings -= strings_for_this_mppt;
//         }
//       }

//       const inverter_details = {
//         max_panels_per_string: max_string_size,
//         min_panels_per_string: min_string_size,
//         max_strings_per_mppt: INPUTS_PER_MPPT,
//         actual_string_size: actual_string_size,
//         actual_strings_per_inverter: Math.ceil(actual_panels_per_inverter / actual_string_size),
//         total_panels: actual_panels
//       };

//       actual_config.push({
//         inverter,
//         mppts,
//         batteries: batteries_for_inverter,
//         inverter_details
//       });
//     }

//     return actual_config;
//   };

//   return {
//     optimal: config,
//     actual: calculateActualConfig(),
//     totals: {
//       "optimalInverters": NUM_INVERTERS,
//       "optimalPanels": best_config.total_panels,
//       "optimalBatteries": roundTo(BATTERY_COUNT, 0),
//       "actualPanels": system_information.number_of_pv,
//       "actualBatteries": BATTERY_COUNT
//     },
//     state:""
//   };
// };


// Utility functions
const transformClampsStatus = (clampsStatus) => {
  return Object.keys(clampsStatus).map(clampId => ({
    id: clampId,
    enabled: clampsStatus[clampId].enabled,
    minus: clampsStatus[clampId].deduct_from_total
  }));
};

const calculateSizingReport = (final_response) => {
  const {

    components,
    hourReadings,
    clampsStatus,
    system_results,
    average_day_kw,
    overall,
    batteryCount,
    inverterCount,
    pvCount, phase
  } = final_response;

  // get the local timezone to adjust the timestamps
  // const timezone = 'Africa/Johannesburg';

  // const formatted_hourReadings = hourReadings.map(reading => ({ ...reading, time: convertToTimezone(reading.time, timezone) }));

  const clamps = transformClampsStatus(clampsStatus);
  // const dailyUseKwh = calculateDailyUseKwh(hourReadings, clamps);
  const dailyUseKwh = average_day_kw * 24;

  if (system_results.solar_panel_result === undefined) {
    system_results.solar_panel_result = {
      panel_generation_kw: 0,
      no_units: 0,
      pv_array_size: 0,
      WP: 0
    }
  }
  const solar_coverage_kw = system_results.solar_panel_result.panel_generation_kw;
  const pvWattsAcAnnual = calculatePvWattsAcAnnual(components.details.panels);


  return {
    dailyUseKwh,
    solar_coverage_kw,
    pvWattsAcAnnual,
    batteryCapacityKwh: components.battery.kwh,
    inverterCapacityKw: components.inverter.rated_output,
    hourReadings,
    clamps,
    panel: components.details.panels[0],
    noPanels: pvCount,
    components,
    system_results,
    average_day_kw,
    batteryCount,
    inverterCount,
    pvCount,
    overall,
    phase
  };
};



const processSizingReport = (final_response, batteryCount, inverterCount, pvCount) => {


  // console.log("first report", final_response);
  final_response.batteryCount = batteryCount;
  final_response.inverterCount = inverterCount;
  final_response.pvCount = pvCount;
  const sizingReport = calculateSizingReport(final_response);
  const { system_results } = sizingReport;

  try {
    let dailySizingViewEstimate;
    let weeklyUsageSimulation;

    if (system_results.solar_panel_result.pv_array_size === 0) {
      dailySizingViewEstimate = null;
      weeklyUsageSimulation = null;
    } else {
      dailySizingViewEstimate = calculateDailySizingViewEstimate(sizingReport);
      weeklyUsageSimulation = calculateWeeklyUsageSimulation(sizingReport);
    }
    const estimatedComponentCosts = calculateEstimatedComponentCosts(sizingReport);
    const componentConfiguration = calculateComponentConfiguration(
      sizingReport.components.details.inverters,
      sizingReport.components.details.panels,
      pvCount
    );

    const inverterOperatingCapacityProjectionData = calculateInverterOperatingCapacityGraphData(sizingReport.hourReadings, inverterCount, sizingReport.inverterCapacityKw, sizingReport.phase === 'three' ? true : false);


    return {
      dailySizingViewEstimate,
      weeklyUsageSimulation,
      estimatedComponentCosts,
      componentConfiguration,
      inverterOperatingCapacityProjectionData
    };
  } catch (error) {
    console.error("Error processing sizing report:", error);
    return {
      dailySizingViewEstimate: null,
      weeklyUsageSimulation: null,
      estimatedComponentCosts: null,
      componentConfiguration: null,
      inverterOperatingCapacityProjectionData: null

    };
  }
};

// Ensure all functions are exported correctly
export {
  processSizingReport,
  calculateEstimatedComponentCosts,
  calculateComponentConfiguration,
};