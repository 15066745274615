import React, { useEffect, useState } from "react";
import { Descriptions, Input, Tag, Alert, Button, message, Modal, notification, Card, Table, Tooltip, Select, Flex } from "antd";
import { fetchComponents, pushComponent, deleteComponent } from '../services/apiService';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
const { Search } = Input;
const { Option } = Select;

function ComponentsPage() {
    const [data, setData] = useState([]);
    const [components, setComponents] = useState([]);
    const [selectedComponentData, setSelectedComponentData] = useState(null);
    const [selectedComponentDataSource, setSelectedComponentDataSource] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [changes, setChanges] = useState([]);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [currentlyEditingUuid, setCurrentlyEditingUuid] = useState(null);
    const [isCreatingNewComponent, setIsCreatingNewComponent] = useState(false);
    const [newComponentType, setNewComponentType] = useState(null);
    const [newComponentData, setNewComponentData] = useState({});

    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [componentToDelete, setComponentToDelete] = useState(null);

    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('accessToken');
            const response = await fetchComponents(token);
            setComponents(response);
            setData(formatData(response));
        };
        fetchData();
    }, []);

    const formatData = (components) => {
        const formattedData = [];
        components.inverters.forEach(inverter => {
            formattedData.push({
                key: inverter._id,
                uuid: inverter.component_uuid,
                name: inverter.name,
                make: inverter.make,
                type: 'Inverter',
                designVoltage: inverter.design_voltage,
                price: `${inverter.price} ZAR`,
                updated: inverter.updated,
                created: inverter.created,
                editor: inverter.modified_by,
            });
        });
        components.batteries.forEach(battery => {
            formattedData.push({
                key: battery._id,
                uuid: battery.component_uuid,
                name: battery.name,
                make: battery.make,
                type: 'Battery',
                designVoltage: battery.design_voltage,
                voltage: battery.voltage,
                price: `${battery.price} ZAR`,
                updated: battery.updated,
                created: battery.created,
                editor: battery.modified_by,
            });
        });
        components.panels.forEach(panel => {
            formattedData.push({
                key: panel._id,
                uuid: panel.component_uuid,
                name: panel.name,
                make: panel.make,
                type: 'Panel',
                designVoltage: `-`,
                price: `${panel.price} ZAR`,
                updated: panel.updated,
                created: panel.created,
                editor: panel.modified_by,
            });
        });
        return formattedData;
    };

    const getColorByType = (type) => {
        switch (type) {
            case 'Inverter':
                return 'orange';
            case 'Battery':
                return 'blue';
            case 'Panel':
                return 'yellow';
            default:
                return 'green';
        }
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Product Code',
            dataIndex: 'uuid',
            key: 'uuid',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            filters: [
                { text: 'Inverter', value: 'Inverter' },
                { text: 'Battery', value: 'Battery' },
                { text: 'Panel', value: 'Panel' },
            ],
            onFilter: (value, record) => record.type === value,
            render: type => (
                <Tag color={getColorByType(type)}>
                    {type.toUpperCase()}
                </Tag>
            ),
        },
        {
            title: 'Make',
            dataIndex: 'make',
            key: 'make',
            filters: Array.from(new Set(data.map(item => item.make))).map(make => ({
                text: make,
                value: make,
            })),
            onFilter: (value, record) => record.make === value,
        },
        {
            title: 'Design Voltage',
            dataIndex: 'designVoltage',
            key: 'designVoltage',
        },
        {
            title: 'Created',
            dataIndex: 'created',
            key: 'created',
            sorter: (a, b) => new Date(a.created) - new Date(b.created),
        },
        {
            title: 'Updated',
            dataIndex: 'updated',
            key: 'updated',
            sorter: (a, b) => new Date(a.updated) - new Date(b.updated),
        },
        {
            title: 'Last Editor',
            dataIndex: 'editor',
            key: 'editor',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <>
                    <Button
                        type="primary"
                        onClick={() => handleEdit(record.uuid)}
                        disabled={currentlyEditingUuid === record.uuid} 
                    >
                        Modify
                    </Button>
                    <Button danger style={{ marginLeft: 8 }} onClick={() => showDeleteModal(record)}>
                        Delete
                    </Button>
                </>
            ),
        }
    ];

    const handleSearch = (value) => {
        const filteredData = formatData(components).filter(item =>
            item.name.toLowerCase().includes(value.toLowerCase())
        );
        setData(filteredData);
    };

    const showDeleteModal = (record) => {
        setComponentToDelete(record);
        setIsDeleteModalVisible(true);
    };

    const handleDelete = async () => {
        const token = localStorage.getItem('accessToken');
        try {
            await deleteComponent(token, componentToDelete.key, componentToDelete.type);
            notification.success({
                message: 'Component deleted successfully!',
                description: 'The component has been deleted successfully.',
            });
            const fetchData = async () => {
                const updatedComponents = await fetchComponents(token);
                setComponents(updatedComponents);
                setData(formatData(updatedComponents));
            };
            fetchData();
            setIsDeleteModalVisible(false);
            setComponentToDelete(null);
        } catch (error) {
            notification.error({
                message: 'Failed to delete component',
                description: 'An error occurred while deleting the component.',
            });
        }
    };

    const singularizeType = (type) => {
        const typeMapping = {
            inverters: 'Inverter',
            batteries: 'Battery',
            panels: 'Panel'
        };
        return typeMapping[type] || type.charAt(0).toUpperCase() + type.slice(1, -1);
    };

    const findComponentByUuid = (uuid, components) => {
        const componentTypes = ['inverters', 'batteries', 'panels'];
        for (const type of componentTypes) {
            const component = components[type].find(item => item.component_uuid === uuid);
            if (component) {
                return { component, type: singularizeType(type) };
            }
        }
        return { component: null, type: null };
    };

    const handleEdit = (uuid) => {
        let result = findComponentByUuid(uuid, components);
        let component = result.component;
        let type = result.type;

        if (component) {
            const dataSource = [{
                key: component._id,
                type: type,
                ...component,
            }];

            setSelectedComponentDataSource(dataSource);
            setSelectedComponentData(component);
            setCurrentlyEditingUuid(uuid);
            message.success('Component data loaded successfully!');
            checkForChanges(component, dataSource[0]);
        } else {
            message.error('Component not found.');
        }
    };

    const handleSave = async () => {
        const token = localStorage.getItem('accessToken');
        const updatedComponent = { ...selectedComponentDataSource[0] };

        updatedComponent.updated = new Date().toISOString();

        try {
            await pushComponent(token, updatedComponent);
          
            notification.success({
                message: 'Changes saved successfully!',
                description: 'The component template has been updated successfully.',
            });
            setSelectedComponentData(updatedComponent);

            const fetchData = async () => {
                const updatedComponents = await fetchComponents(token);
                setComponents(updatedComponents);
                setData(formatData(updatedComponents));
            };
            fetchData();
            handleClear();
        } catch (error) {
            notification.error({
                message: 'Failed to save changes',
                description: 'An error occurred while saving the component template.',
            });
        }
    };

    const handleInputChange = (e, key) => {
        const newData = { ...selectedComponentDataSource[0], [key]: e.target.value };
        setSelectedComponentDataSource([newData]);
        checkForChanges(selectedComponentData, newData);
    };

    const handleNewComponentInputChange = (e, key) => {
        const newData = { ...newComponentData, [key]: e.target.value };
        setNewComponentData(newData);
        validateForm(newData);
    };

    const validateForm = (data) => {
        const isValid = Object.values(data).every(value => value !== '');
        setIsFormValid(isValid);
    };

    const showModal = () => {
        const originalData = selectedComponentData;
        const modifiedData = selectedComponentDataSource[0];
        const changes = [];

        for (const key in originalData) {
            if (originalData[key] !== modifiedData[key]) {
                changes.push({
                    field: key,
                    original: originalData[key],
                    modified: modifiedData[key],
                });
            }
        }

        setChanges(changes);
        setIsModalVisible(true);
    };

    const handleOk = async () => {
        setIsModalVisible(false);
        handleSave();
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleClear = () => {
        setSelectedComponentDataSource([]);
        setSelectedComponentData(null);
        setIsSaveDisabled(true);
        setCurrentlyEditingUuid(null);
    };

    const checkForChanges = (originalData, modifiedData) => {
        for (const key in originalData) {
            if (originalData[key] !== modifiedData[key]) {
                setIsSaveDisabled(false);
                return;
            }
        }
        setIsSaveDisabled(true);
    };

    const renderChangesTable = () => {
        const columns = [
            {
                title: 'Field',
                dataIndex: 'field',
                key: 'field',
            },
            {
                title: 'Original Value',
                dataIndex: 'original',
                key: 'original',
            },
            {
                title: 'Modified Value',
                dataIndex: 'modified',
                key: 'modified',
                render: (text) => (
                    <span style={{ backgroundColor: '#d4edda', padding: '2px 4px' }}>
                        {text}
                    </span>
                ),
            },
        ];

        return (
            <Table
                columns={columns}
                dataSource={changes.map((change, index) => ({ ...change, key: index }))}
                pagination={false}
                size="small"
            />
        );
    };

    const excludedKeys = ['key', '_id', 'type', 'created', 'updated', 'modified_by'];

    const handleCreateNewComponent = () => {
        setIsCreatingNewComponent(true);
        setNewComponentType('please select a type');
        setNewComponentData({});
        setIsFormValid(false);
    };

    const handleSelectComponentType = (type) => {
        setNewComponentType(type);
        const template = {
            Inverter: {
                component_uuid: "",
                name: "",
                make: "",
                rated_output: 0,
                price: 0,
                parallel_limit: 0,
                max_input_voltage: 0,
                num_mppts: 0,
                num_inputs_per_mppts: 0,
                max_charging_per_mppts_ISC: 0,
                design_voltage: "HV",
                handlesThreePhase: false,
                updated: "2024-10-18T09:52:57.411Z",
                created: "2024-10-18T09:34:03.561Z",
                modified_by: "user"
            },
            Battery: {
                component_uuid: "",
                name: "",
                make: "",
                kwh: 8,
                max_percent: 1,
                min_percent: 0,
                peak_kw: 363.52,
                loss_factor: 0.15,
                price: 26000,
                DoD: 290.816,
                max_dod_percent: 0.8,
                design_voltage: "HV",
                voltage: 52,
                max_per_stack: 12,
                bmu_needed: true,
                balance_between_inverters: true,
                updated: "2024-10-18T09:34:03.561Z",
                created: "2024-10-18T09:34:03.561Z",
                modified_by: "user"
            },
            Panel: {
                name: "",
                make: "",
                WP: 555,
                VOC: 49.72,
                VMP: 40.99,
                ISC: 14.12,
                Temp_coEff_VC: -0.0028,
                price: 3000,
                component_uuid: "",
                height_mm: 2182,
                width_mm: 1029,
                weight: 26.1,
                updated: "2024-10-18T09:53:59.024Z",
                created: "2024-10-18T09:34:03.561Z",
                modified_by: "user"
            },
        };
        setNewComponentData(template[type]);
    };

    const handleSaveNewComponent = async () => {
        const token = localStorage.getItem('accessToken');
        const newComponent = { ...newComponentData, type: newComponentType, created: new Date().toISOString(), updated: new Date().toISOString() };

        try {
            await pushComponent(token, newComponent);
            notification.success({
                message: 'Component created successfully!',
                description: 'The new component has been created successfully.',
            });
            const fetchData = async () => {
                const updatedComponents = await fetchComponents(token);
                setComponents(updatedComponents);
                setData(formatData(updatedComponents));
            };
            fetchData();
            setIsCreatingNewComponent(false);
        } catch (error) {
            notification.error({
                message: 'Failed to create component',
                description: 'An error occurred while creating the new component.',
            });
        }
    };

    const tooltipTextMapping = {
        component_uuid: "This refers to the product id in the store",
        design_voltage: "This is the design voltage of the component. Can be HV or LV",
        make: "The Brand of the Component",
        weight: "The weight of the component in kg",
        price: "The price of the component in ZAR",
        // Add more mappings as needed
    };


    const renderDescriptionItem = (key, value, handleInputChange) => {
        if (excludedKeys.includes(key)) {
            return null;
        }
    
        return (
            <Descriptions.Item label={
                tooltipTextMapping[key] ? (
                    <span>
                        {key} <Tooltip title={tooltipTextMapping[key]}><FontAwesomeIcon
                                            icon={faQuestionCircle}
                                            style={{ marginLeft: "8px" }}
                                        /></Tooltip>
                    </span>
                ) : key
            } key={key}>
                <Input
                    value={value}
                    onChange={(e) => handleInputChange(e, key)}
                />
            </Descriptions.Item>
        );
    };

    return (
        <div>
            <h1>Components Page</h1>
           
            
            <Alert
                message="Caution"
                description="As a super admin, you are able to modify the global component templates for all users. Be careful and make sure you know what you are doing! These attribute changes will affect all calculations for sizing and other methods across this system."
                type="warning"
                showIcon
                style={{ marginBottom: 16 }}
            />
            <div style={{ marginBottom: 16 }}>
                <Search
                    placeholder="Search by name"
                    onSearch={handleSearch}
                    style={{ width: 200 }}
                />
            </div>
            <Button type="primary" onClick={handleCreateNewComponent}> Create New Component </Button>
            <br />
            <Modal
    title="Confirm Deletion"
    open={isDeleteModalVisible}
    onCancel={() => setIsDeleteModalVisible(false)}
    footer={[
        <Button key="back" onClick={() => setIsDeleteModalVisible(false)}>
            Cancel
        </Button>,
        <Button key="submit" type="primary" danger onClick={handleDelete}>
            Delete
        </Button>,
    ]}
>
    <p>Are you sure you want to delete the component <strong>{componentToDelete?.name}</strong>?</p>
    <p>This action cannot be undone.</p>
</Modal>
            <br />
            <Table columns={columns} dataSource={data} />

            {isCreatingNewComponent && (
    <div>
        <Alert message="Be aware that this new component will be available for selection to all users on system" type="info" showIcon />
        <br />
        <Card title="Creating New Component" style={{ marginBottom: '20px' }}>
            <Descriptions bordered column={1}>
                <Descriptions.Item label="Component Type">
                    <Select value={newComponentType} onChange={handleSelectComponentType}>
                        <Option value="Inverter">Inverter</Option>
                        <Option value="Battery">Battery</Option>
                        <Option value="Panel">Panel</Option>
                    </Select>
                </Descriptions.Item>
                {Object.keys(newComponentData).map((key) => (
                    renderDescriptionItem(key, newComponentData[key], handleNewComponentInputChange)
                ))}
            </Descriptions>
        </Card>
        <Flex gap="small">
        <Button type="primary" onClick={handleSaveNewComponent} disabled={!isFormValid}>Save New Component</Button>
        <Button type="default" onClick={() => setIsCreatingNewComponent(false)}>Cancel</Button>
        </Flex>
    </div>
)}

            {currentlyEditingUuid ? (
            <div>
                <p>Feel free to edit the values in the table below to update the component&apos;s attributes.</p>
                <Alert message="Please note that changes are system wide, and any incorrect configuration could break calculations elsewhere in the app. Please make sure you know what you are doing!" type="warning" showIcon />
                <br />
                {selectedComponentDataSource.map((data, index) => (
                <Card key={index} title={`Editing ${data['type']} ${data['name']}`} style={{ marginBottom: '20px' }}>
                    <Descriptions bordered column={1}>
                        {Object.keys(data)
                            .filter(key => !excludedKeys.includes(key))
                            .map((key) => (
                                <Descriptions.Item label={key} key={key}>
                                    {key === 'component_uuid' ? (
                                        <Tooltip title="This is the product code">
                                            {data[key]}
                                        </Tooltip>
                                    ) : key === 'uuid' | key === 'modified_by' ? (
                                        data[key]
                                    ) : (
                                        <Input
                                            value={data[key]}
                                            onChange={(e) => handleInputChange(e, key)}
                                        />
                                    )}
                                </Descriptions.Item>
                            ))}
                    </Descriptions>
                </Card>
            ))}
                <br />
                <div style={{ display: 'flex', gap: '10px' }}>
                    <Button type="primary" danger onClick={showModal} disabled={isSaveDisabled}> Update </Button>
                    <Button type="default" onClick={handleClear}> Cancel </Button>
                </div>
                  
            </div>
            ) : <div>To get started, click &quot;Modify&quot; on any of the components above</div>}
             
            <br />
            <Modal
                title="Confirm Update"
                open={isModalVisible}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" danger onClick={handleOk}>
                        Update
                    </Button>,
                ]}
            >
                <p>The following changes have been made:</p>
                {renderChangesTable()}
                <br />
                <Alert message="Please review the changes carefully before saving. This will affect all sizing calculations across the system" type="warning" showIcon />
                <p>Are you sure you want to update the attributes of this component template system-wide?</p>
            </Modal>
        </div>
    );
}

export default ComponentsPage;